/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-else-return */
import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  Link,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  faChevronLeft,
  faChevronRight,
  faTrashCan,
  faPlus,
  faFileArrowDown,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';
import Layout from '../../components/template/Layout';
import Table from '../../components/molecules/Table/Table';
import Button from '../../components/atoms/Button/Button';
import Search from '../../components/molecules/Search/Search';
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
import Pagination from '../../components/molecules/Pagination';
import styles from './amendement.module.css';
import useAppContext from '../../store/useAppContext';
import { getContract } from '../../services/contracts';
import { getAmendementsList, getAmendementDocument, deleteAmendement } from '../../services/amendement';
import cn from '../../utils/cn';
import Permissions from '../../components/molecules/Permissions/Permissions';

/* eslint-disable react/prop-types */
function Amendements() {
  const [response, setResponse] = useState([]);
  const [supplierToDelete, setsupplierToDelete] = useState('');
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlParams = useParams();
  const [context] = useAppContext();
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState('');

  const customModalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };

  const getSocietiesListQuery = useQuery(['societiesList', page, search], () => getAmendementsList({
    contractId: urlParams.id,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    search,
    page,
  }), {
    onSuccess: (data) => {
      setResponse(data);
    },
  });

  const getContracts = useQuery(['contracts', urlParams.id], () => getContract(urlParams.id), {
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  });

  const dowloaddoc = (id, data) => {
    window.open(`${process.env.REACT_APP_API_URL}amendments/download-document/${id}/${data}`, '_blank');
  };
  const getAmendementDocuments = useMutation(getAmendementDocument, {
    onSuccess: (data) => {
      dowloaddoc(data.data);
    },
  });

  const deleteAmendementMutation = useMutation(deleteAmendement, {
    onSuccess: () => {
      toast.success(t('contract.suppressionAmendement'));
      getSocietiesListQuery.refetch();
    },
    onError: () => {
      toast.error(t('contract.amendementDeleteError'));
    },
  });

  const handleModal = (selectedInterventionToDelete) => {
    setOpenModal(true);
    setsupplierToDelete(selectedInterventionToDelete);
  };

  const handleDelete = () => {
    setOpenModal(false);
    deleteAmendementMutation.mutate(supplierToDelete);
  };
  const columns = React.useMemo(
    () => [
      {
        Header: `${t('contract.titreAmendement')}`,
        accessor: 'title',
        Cell: ({ row }) => (
          <div>
            {row.original.title}
          </div>
        ),
      },
      {
        Header: `${t('contract.purposeAmendement')}`,
        accessor: 'purpose',
        Cell: ({ row }) => (
          <div>
            {row.original.purpose}
          </div>
        ),
      },
      {
        Header: `${t('contract.documentFileNameAmendement')}`,
        accessor: 'documentFileName',
        Cell: ({ row }) => {
          if (row.original.documentFileName === null) {
            return '-';
          }
          return (
            <div>
              <Button
                label={(
                  <FontAwesomeIcon icon={faFileArrowDown} transform="grow-6" />
                )}
                type="button"
                className="action edit"
                title={t('societies.edit')}
                onClick={() => dowloaddoc(row.original.id, row.original.documentFileName)}
              />
            </div>
          );
        },
      },
      {
        Header: ' ',
        accessor: 'id',
        Cell: ({ row }) => (
          <div>
            {Permissions({ permission: 'AMENDMENT_READ' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Button
                  type="button"
                  className="action edit"
                  label={<FontAwesomeIcon icon={faEdit} />}
                  title={t('contract.edit_amendement')}
                  onClick={() => navigate(`/edit/amendements/${urlParams.id}/${row.original.id}`)}
                />
              ) : null }
            {Permissions({ permission: 'AMENDMENT_DELETE' }) !== undefined
              ? (
                <Button
                  type="button"
                  className="action suppr"
                  label={<FontAwesomeIcon icon={faTrashCan} />}
                  title={t('contract.delete_amendement')}
                  onClick={() => handleModal(row.original.id)}
                />
              ) : null }
          </div>
        ),
      },
    ],
    [],
  );

  const handleSearch = debounce((e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  }, 500);
  return (
    <>
      <Layout
        title={t('contract.title_avenant')}
        layout="table"
        table={response}
        queryError={
          getSocietiesListQuery?.error
          || getAmendementDocuments?.error
          || getContracts?.error
        }
      >
        <header className="header">
          <div className="row mb-20">
            <button type="button" className="link" onClick={() => navigate('/contracts')}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('contract.back_list')}</span>
            </button>
          </div>
          <div className={cn(['row', styles.row])}>
            <h1 className="title">
              {t('contract.amendment')}
              :
              <br />
              <span className="title-info">
                {` ${urlParams?.society} - ${getContracts?.data?.data?.equipmentName}`}
              </span>
            </h1>
            {Permissions({ permission: 'AMENDMENT_CREATE' }) !== undefined
              ? (
                <Link className={cn(['e', 'add col-3'])} to={`/amendement/add/${urlParams?.id}`}>
                  <FontAwesomeIcon icon={faPlus} />
                  <span className="add_label">
                    {t('contract.addAmendment')}
                  </span>
                </Link>
              ) : null }
          </div>
        </header>
        <div className="recherche">
          <div className={styles.searchRow}>
            <Search
              className="mb-20"
              onChange={handleSearch}
            />
          </div>
        </div>
        <div>
          <Table
            columns={columns}
            isLoading={response?.isLoading || getSocietiesListQuery?.isLoading}
            data={response?.data?.amendments || []}
            hasSort
            pagination={(
              <Pagination
                previousLabel={(
                  <FontAwesomeIcon icon={faChevronLeft} />
                )}
                nextLabel={(
                  <FontAwesomeIcon icon={faChevronRight} />
                )}
                onPageChange={onPageChange}
                totalCount={getSocietiesListQuery?.data?.data?.total || 0}
                currentPage={page}
                pageSize={10}
                numberElementsDisplayed={getSocietiesListQuery?.data?.data?.societies?.length || 0}
              />
          )}
          />
        </div>
        <footer>
          <div className="row mt-20">
            <button type="button" className="link" onClick={() => navigate('/contracts')}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('contract.back_list')}</span>
            </button>
          </div>
        </footer>
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyle}
      >
        <p className="modal_paragraph">{t('global.modal_content')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
            onClick={() => handleDelete(supplierToDelete)}
          />
        </div>
      </Modal>
    </>
  );
}
export default Amendements;
