/* eslint-disable max-len */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { faChevronLeft, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

// Component
import Layout from '../../components/template/Layout';
import Input from '../../components/atoms/Input/Input';
import Button from '../../components/atoms/Button/Button';
import Radio from '../../components/atoms/Radio/Radio';
import UploadFile from '../../components/atoms/UploadFile/UploadFile';

// hooks
import useAppContext from '../../store/useAppContext';

// services
import { editReport, createReport, getReport } from '../../services/reports';
import { getIntervention } from '../../services/interventions';
import { getSociety } from '../../services/societies';

// styles
import styles from './AddFillingReport.module.css';
import cn from '../../utils/cn';

function AddFillingReport() {
  const { t } = useTranslation();
  const urlParams = useParams();
  const navigate = useNavigate();
  const [context] = useAppContext();
  const { state } = useLocation();
  const [controlId, setControlId] = useState();
  const isEditing = urlParams.action === 'edit';
  const isViewing = urlParams.action === 'view';
  const isCreating = !isEditing && !isViewing;

  const goBackUrl = (message) => {
    navigate(-1);
    toast.success(message);
  };

  const initialValues = {
    reportDate: '',
    interventionId: state.interventionId,
    societyInChargeName: '',
    reference: '',
    reportFile: null,
    favorable: 1,
    observations: [],
    isCreating,
  };

  const validationSchema = Yup.object().shape({
    isCreating: Yup.boolean(),
    reportDate: Yup.string().nullable().required(t('global.required_field')),
    societyInChargeName: Yup.string().nullable().typeError(t('global.wrong_type')),
    reference: Yup.string().nullable().typeError(t('global.wrong_type')),
    reportFile: Yup.mixed().nullable().typeError(t('global.wrong_type'))
      .when('isCreating', {
        is: true,
        then: Yup.mixed().required('global.required_field')
          .test('fileSize', 'global.file_too_large', (value) => value && value.size <= 10000000)
          .test('type', 'global.accepted_formats', (value) => value && (value.type === 'application/pdf'
            || value.type === 'application/x-pdf'
            || value.type === 'image/jpeg'
            || value.type === 'image/jpg'
            || value.type === 'image/png'
            || value.type === 'image/tiff'
            || value.type === 'image/bmp'
            || value.type === 'image/heic'
            || value.type === 'image/vnd.dwg')),
        otherwise: Yup.mixed(),
      }),
    favorable: Yup.string().nullable().typeError(t('global.wrong_type')),
    observations: Yup.array().of(Yup.object().shape({
      name: Yup.string().nullable().required(t('global.required_field')).typeError(t('global.wrong_type')),
      number: Yup.string().nullable().required(t('global.required_field')).typeError(t('global.wrong_type')),
    })),
  });

  const postReportMutation = useMutation(createReport, {
    onSuccess: () => goBackUrl(t('addReports.added')),
  });
  const editReportMutation = useMutation(editReport, {
    onSuccess: () => goBackUrl(t('addReports.edited')),
  });

  const getSocietyQuery = useQuery('society', () => getSociety(state.societyInChargeId), {
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    enabled: !!state.societyInChargeId,
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append('interventionId', values.interventionId);
      values.observations.forEach((observation, index) => {
        formData.append(`observations[${index}][title]`, observation.name);
        formData.append(`observations[${index}][number]`, observation.number);
      });
      if (values.reference !== '') {
        formData.append('reference', values.reference);
      }
      formData.append('reportDate', values.reportDate);
      if (controlId === 72 || controlId === 71) {
        formData.append('securityNotice', values.favorable);
      }
      if (values.reportFile) {
        formData.append('reportFile', values.reportFile);
      }
      if (isEditing) {
        const config = {
          id: urlParams?.id,
          data: formData,
        };
        editReportMutation.mutate(config);
      } else {
        postReportMutation.mutate(formData);
      }
    },
  });

  const getInterventionQuery = useQuery('intervention', () => getIntervention(state.interventionId), {
    onSuccess: (datas) => {
      formik.setFieldValue('societyInChargeName', datas?.data?.societyName);
      setControlId(datas?.data?.control?.id);
    },
  });
  const deleteObservation = (id) => {
    const updatedObservations = [...formik.values.observations];
    updatedObservations.splice(id, 1);
    formik.setFieldValue('observations', updatedObservations);
  };

  const getReportQuery = useQuery(['report'], () => getReport({
    id: urlParams.id,
  }), {
    enabled: !!urlParams.id && urlParams.action === 'edit',
    onSuccess: (data) => {
      formik.setFieldValue('reportDate', format(new Date(data?.data?.reportDate), 'yyyy-MM-dd'));
      formik.setFieldValue('reference', data?.data?.reference);
      formik.setFieldValue('favorable', data?.data?.securityNotice);
      formik.setFieldValue('reportFileName', data?.data?.reportFileName);
    },
  });
  console.log('formik', formik.errors);
  console.log('formik', formik.values);
  return (
    <Layout
      title={isCreating
        ? t('addReports.title_create')
        : t('addReports.title_edit')}
      queryError={
        editReportMutation?.error
        || postReportMutation?.error
        || getInterventionQuery?.error
        || getSocietyQuery?.error
        || getReportQuery?.error
      }
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{context?.choiceBooklet === 3 ? t('addReports.back_to_docs') : t('addReports.back_to_reports')}</span>
          </button>
        </div>
        <div className="row">
          <h1 className="title">
            {context?.choiceBooklet === 3 ? t('addReports.title_deposit_doc') : t('addReports.title_add_report')}
            <br />
            <span className="title-info">
              {getInterventionQuery?.data?.data?.title}
            </span>
          </h1>
        </div>
      </header>
      <form onSubmit={formik.handleSubmit} className="form shadow-sm">
        <div className="form_group">
          <Input
            id="reportDate"
            name="reportDate"
            type="date"
            label={context.choiceBooklet === 3 ? t('addReports.doc_date') : t('addReports.report_date')}
            value={formik.values.reportDate}
            onChange={(e) => formik.setFieldValue('reportDate', e.target.value)}
            min="2000-01-01"
            max="2099-12-31"
          />
          {formik.errors.reportDate && formik.touched.reportDate ? (
            <div className="error">
              {t(formik.errors.reportDate)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <div className="label">
            {context.choiceBooklet === 3 ? t('addReports.doc') : t('addReports.report')}
          </div>
          <UploadFile
            id="reportFile"
            name="reportFile"
            label={t('addReports.report')}
            fileName={formik.values.reportFile ? formik.values.reportFile.name : formik.values.reportFileName}
            onChange={(event) => formik.setFieldValue('reportFile', event.currentTarget.files[0])}
            onBlur={formik.handleBlur}
            setFieldValue={formik.setFieldValue}
          />
          {formik.errors.reportFile && formik.touched.reportFile ? (
            <div className="error">
              {t(formik.errors.reportFile)}
            </div>
          ) : null }
        </div>
        {context?.choiceBooklet !== 3 && (
          <>
            <div className="form_group">
              <Input
                id="reference"
                name="reference"
                type="text"
                label={t('addReports.report_number')}
                value={formik.values.reference}
                onChange={(e) => formik.setFieldValue('reference', e.target.value)}
              />
              {formik.errors.reference && formik.touched.reference ? (
                <div className="error">
                  {t(formik.errors.reference)}
                </div>
              ) : null }
            </div>
            <div className="form_group">
              <Input
                id="societyInChargeName"
                name="societyInChargeName"
                type="text"
                label={t('addReports.society')}
                value={formik.values.societyInChargeName || '-'}
                disabled
              />
              {formik.errors.societyInChargeName && formik.touched.societyInChargeName ? (
                <div className="error">
                  {t(formik.errors.societyInChargeName)}
                </div>
              ) : null }
            </div>
          </>
        )}
        {context?.choiceBooklet !== 3 && getInterventionQuery?.data?.data?.type ? (
          <div className="form_group">
            <span className="label">
              {t('addReports.report_type')}
              {' '}
              :
            </span>
            {' '}
            {getInterventionQuery?.data?.data?.equipment.equipmentFamily.name === 'Commission De Sécurité'
              ? t('addReports.safety_committee')
              : t(`addReports.${getInterventionQuery?.data?.data?.type}`)}
          </div>
        ) : null }
        <div className="form_group">
          {getInterventionQuery?.data?.data?.control?.id === 72 || getInterventionQuery?.data?.data?.control?.id === 71 ? (
            <div className={styles.radio}>
              <div className={styles.radio__favorable}>
                <Radio
                  id="favorable"
                  name="favorable"
                  label={t('addReports.favorable')}
                  value={1}
                  checked={formik.values.favorable === 1 || formik.values.favorable === true}
                  onChange={(e) => formik.setFieldValue('favorable', parseInt(e.target.value, 10))}
                />
              </div>
              <div className={styles.radio__defavorable}>
                <Radio
                  id="defavorable"
                  name="defavorable"
                  label={t('addReports.defavorable')}
                  value={0}
                  checked={formik.values.favorable === 0 || formik.values.favorable === false}
                  onChange={(e) => formik.setFieldValue('favorable', parseInt(e.target.value, 10))}
                />
              </div>
            </div>
          ) : null }
        </div>
        { !isEditing && context?.choiceBooklet !== 3
          ? (
            <section className="observations form_group form_group--fullwidth form_group--highlighted">
              <h3 className="title">{t('addReports.observations')}</h3>
              {formik.values.observations.length ? formik.values.observations.map((observation, index) => (
                <div className="form_row">
                  <div className="form_group">
                    <Input
                      id="observationName"
                      name="observationName"
                      type="text"
                      label={t('addReports.name')}
                      value={observation.name}
                      onChange={(e) => formik.setFieldValue(
                        'observations',
                        formik.values.observations.map((obser, indexObservation) => (indexObservation === index
                          ? { name: e.target.value, number: obser.number }
                          : obser)),
                      )}
                    />
                    {formik.errors.observations && formik?.errors?.observations?.[index]?.name && formik?.touched?.observations?.[index]?.name ? (
                      <div className="error">
                        {formik.errors.observations?.[index]?.name}
                      </div>
                    ) : null }
                  </div>
                  <div className="form_group">
                    <Input
                      id="observationNumber"
                      name="observationNumber"
                      type="text"
                      label={t('addReports.number')}
                      value={observation.number}
                      onChange={(e) => formik.setFieldValue(
                        'observations',
                        formik?.values?.observations?.map((obser, indexObservation) => (indexObservation === index
                          ? { name: obser.name, number: e.target.value }
                          : obser)),
                      )}
                    />
                    {formik.errors.observations && formik?.errors?.observations?.[index]?.number && formik?.touched?.observations?.[index]?.number ? (
                      <div className="error">
                        {formik?.errors?.observations?.[index]?.number}
                      </div>
                    ) : null }
                  </div>
                  <section className={cn([styles.form_buttons, 'form_button'])}>
                    <div className={cn([styles.form_delete, 'form_button'])}>
                      <Button
                        type="button"
                        label={<FontAwesomeIcon icon={faMinus} />}
                        onClick={() => deleteObservation(index)}
                      />
                    </div>
                  </section>
                </div>
              )) : null }
              <Button
                type="button"
                className={cn([styles.addObsButton, 'form_button'])}
                label={t('addReports.add_observations')}
                icon={<FontAwesomeIcon icon={faPlus} />}
                onClick={() => formik.setFieldValue('observations', formik.values.observations.concat({ name: '', number: '' }))}
              />
            </section>
          ) : null }
        <div className="form_footer">
          <div className="form_infos">
            <small>{t('addReports.mandatory_fields')}</small>
          </div>
          <Button
            type="submit"
            className="form_submit"
            label={context.choiceBooklet === 3 ? t('reports.sauvegarde') : t('addReports.submit')}
            isLoading={editReportMutation.isLoading || postReportMutation.isLoading}
          />
        </div>
      </form>
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{context?.choiceBooklet === 3 ? t('addReports.back_to_docs') : t('addReports.back_to_reports')}</span>
        </button>
      </footer>
    </Layout>
  );
}

export default AddFillingReport;
