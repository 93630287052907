/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// hooks
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import useAppContext from '../../store/useAppContext';

// Components
import Layout from '../../components/template/Layout';
import Card from '../../components/atoms/Card/Card';
import Picto from '../../components/atoms/Picto/Picto';
import UserDashboard from './UserDashboard/UserDashboard';
import { getPermissions } from '../../services/permissions';
// utils & misc
import { USERS_ROLES } from '../../utils/constant';
import cn from '../../utils/cn';

// style
import styles from './Dashboard.module.css';
// import { getUserMe } from '../../services/users';
import { getIndicatorByEtablissement } from '../../services/indicator';

function DashboardUser() {
  const [context, dispatch] = useAppContext();
  const { t } = useTranslation();
  // const getUserMeQuery = useQuery('get-user-me', getUserMe, {
  //   enabled: !!context?.user,
  // });
  const getIndicatorByEtablissementQuery = useQuery(['establishments'], () => getIndicatorByEtablissement({
    Establissement: context?.choiceEstablishment?.id,
    bookletId: context?.choiceBooklet,
  }));
  const isUserSuperAdmin = context.user.role === USERS_ROLES.SUPER_ADMIN || context.user.role === USERS_ROLES.ADMIN;
  const isUser = context.user.role === USERS_ROLES.USER;
  const isSecurityBooklet = context.choiceBooklet === 2;

  const permissionsQuery = useQuery('permissions', () => getPermissions({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    enabled: context?.permissions?.[`${context?.choiceBooklet}/${context?.choiceEstablishment?.id}`] === undefined,
    onSuccess: ({ data }) => {
      const permissions = { ...context?.permissions };
      permissions[`${context?.choiceBooklet}/${context?.choiceEstablishment?.id}`] = data?.rightsList;
      dispatch({ type: 'SET_PERMISSIONS', payload: permissions });
    },
  });

  useEffect(() => {
    if (permissionsQuery.isLoading) {
      toast.info(t('navigation.perm'), { autoClose: 1000 });
    }
  }, [permissionsQuery.isLoading]);

  return (
    <Layout
      title="Dashboard"
      queryError={getIndicatorByEtablissementQuery?.error || permissionsQuery?.error}
    >
      <Card
        borderTopColored={isUser}
        className={
          cn([styles.topTitleWrapper, isUserSuperAdmin && styles.alignedCenter])
        }
      >
        <div className={cn([styles.topTitle, styles.alignedCenter])}>
          <Picto className={styles.topPicto} name="homeblue" />
          <h1 className="title no-margin">{t('navigation.dashboard')}</h1>
          {isUserSuperAdmin && <Picto className={styles.topPicto} name="homeblue" />}
        </div>
        {isSecurityBooklet && (
          <div className={styles.rightUserSecurity}>
            <div className={styles.lastCommissionWrapper}>
              {getIndicatorByEtablissementQuery?.data?.data?.lastSecurityCommitteeDate && (
                <p className={styles.lastCommission}>
                  Dernière commission :
                  {' '}
                  {new Date(getIndicatorByEtablissementQuery?.data?.data?.lastSecurityCommitteeDate?.date).toLocaleDateString('fr-FR')}
                </p>
              )}
              {getIndicatorByEtablissementQuery?.data?.data?.nextSecurityCommitteeDate && (
                <p className={styles.lastCommission}>
                  Prochaine commission :
                  {' '}
                  {new Date(getIndicatorByEtablissementQuery?.data?.data?.nextSecurityCommitteeDate?.date).toLocaleDateString('fr-FR')}
                </p>
              )}
            </div>
            {getIndicatorByEtablissementQuery?.data?.data?.lastSecurityCommitteeDate !== null && (
              <div>
                {getIndicatorByEtablissementQuery?.data?.data?.lastSecurityCommitteeNotice === null ? (
                  <div className={styles.evaluation}>En attente</div>
                ) : (
                  <div>
                    {getIndicatorByEtablissementQuery?.data?.data?.lastSecurityCommitteeNotice === 1 ? (
                      <div className={styles.evaluation}>Favorable</div>
                    ) : (
                      <div className={styles.deevaluation}>Défavorable</div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </Card>
      <UserDashboard />
      {/* { isUser && isUserImpersonated && <UserDashboard /> }
      { isAdminGroup && isUserSuperAdmin && <UserDashboard /> } */}
    </Layout>
  );
}

export default DashboardUser;
