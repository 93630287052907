/* eslint-disable arrow-body-style */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-else-return */
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import {
  faChevronLeft, faFileArrowDown, faChevronRight, faTrashCan, faSortUp, faSortDown, faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from '../../../utils/cn';
import Button from '../../atoms/Button/Button';
import Pagination from '../../molecules/Pagination';
import styles from './AnalyseList.module.css';
import useTableFiltersPagination from '../../../hooks/useTableFiltersPagination';
import { deleteAnalyse } from '../../../services/analyses';
import Permissions from '../../molecules/Permissions/Permissions';

function AnalyseList({ AnalysisList, deleteOn }) {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [sort, setSort] = useState('asc');
  const [datanull, setDatanull] = useState(false);
  const [info, setInfo] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [analyseToDelete, setAnalyseToDelete] = useState({
    id: null,
  });
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });

  const customModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };

  useEffect(() => {
    if (AnalysisList) {
      setData(AnalysisList?.data?.data?.analyses);
      setInfo(AnalysisList?.data);
      setDatanull(AnalysisList?.data?.data?.total);
    }
  }, [AnalysisList]);

  const deleteAnalysMutate = useMutation(deleteAnalyse, {
    onSuccess: () => {
      toast.success(t('analyses.delete_success'));
      setOpenModal(false);
      AnalysisList.refetch();
    },
  });

  const calculeRange = (pages, size) => {
    const start = (pages - 1) * size;
    const end = start + size;
    if (end > data?.length) {
      return { start, end };
    }
    return { start, end: end - 1 };
  };

  const { start, end } = calculeRange(page, 10);
  const dataPage = data?.slice(start, end);

  const sortarray = (arra, fiel) => {
    const array = arra.slice(0, arra.length);
    if (fiel === 'type') {
      if (sort === 'asc') {
        const sorted = array.sort((a, b) => a.type.localeCompare(b.type));
        setData(sorted);
        setSort('desc');
      }
      if (sort === 'desc') {
        const sorted = array.sort((a, b) => b.type.localeCompare(a.type));
        setData(sorted);
        setSort('asc');
      }
      if (sort === '') {
        const sorted = array.sort((a, b) => a.type.localeCompare(b.type));
        setData(sorted);
        setSort('desc');
      }
    }
    if (fiel === 'date') {
      if (sort === 'asc') {
        const sorted = array
          .sort((a, b) => a?.samplingDate.localeCompare(b?.samplingDate));
        setData(sorted);
        setSort('desc');
      }
      if (sort === 'desc') {
        const sorted = array
          .sort((a, b) => b?.samplingDate.localeCompare(a?.samplingDate));
        setData(sorted);
        setSort('asc');
      }
      if (sort === '') {
        setData(dataPage);
        setSort('desc');
      }
    }
  };

  const couleur = (isPneumophila, quantity) => {
    const parseQuantity = parseInt(quantity, 10);
    if (parseQuantity <= 10 || quantity === null || quantity === '') {
      return styles.green;
    } else if (parseQuantity < 1000 && isPneumophila === true) {
      return styles.orange;
    } else if (parseQuantity >= 1000 && isPneumophila === true) {
      return styles.red;
    } else {
      return styles.orange;
    }
  };

  const displayPneumophila = (isPneumophila, quantity) => {
    if (quantity <= 10) {
      return 'Non detectées';
    } else if (isPneumophila === true) {
      return 'Pneumophila';
    } else {
      return 'Non Pneumophila';
    }
  };

  const handleModal = (selectedBuildingTemplateToDelete) => {
    setOpenModal(true);
    setAnalyseToDelete(selectedBuildingTemplateToDelete);
  };

  const handleDelete = () => {
    setOpenModal(false);
    deleteAnalysMutate.mutate(analyseToDelete);
  };

  const handleAccordionClick = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
      return;
    }
    setActiveIndex(index);
  };

  const dowloaddoc = (id, name) => {
    window.open(`${process.env.REACT_APP_API_URL}analyses/download-document/${id}/${name}`, '_blank');
  };

  const isPneumophila = (analyse) => {
    return analyse.analysisPoints?.some((point) => point.quantity >= 1000) ? cn([styles.cell, styles.red]) : cn([styles.cell]);
  };

  const otherAnalyse = (analyse) => {
    return analyse.analysisPoints?.some((point) => point.valid === false) ? cn([styles.cell, styles.red]) : cn([styles.cell]);
  };

  return (
    <div className={styles.tableContainer}>
      <div className={cn([styles.wrapper, styles.shadowSm])}>
        <table className={styles.table}>
          <thead className={styles.tableHeader}>
            <tr>
              <th
                className={cn([styles.cell, styles.cellHeader])}
              >
                {t('analyses.type')}
                {' '}
                {sort === 'asc' ? (
                  <FontAwesomeIcon
                    icon={faSortUp}
                    onClick={() => sortarray(data, 'type')}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faSortDown}
                    onClick={() => sortarray(data, 'type')}
                  />
                )}
              </th>
              <th className={cn([styles.cell, styles.cellHeader])}>
                {t('analyses.date')}
                {' '}
                {sort === 'asc' ? (
                  <FontAwesomeIcon
                    icon={faSortUp}
                    onClick={() => sortarray(data, 'date')}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faSortDown}
                    onClick={() => sortarray(data, 'date')}
                  />
                )}
              </th>
              <th className={cn([styles.cell, styles.cellHeaderdoc])}>{t('analyses.document')}</th>
              <th className={cn([styles.cellAccordion, styles.cellHeader])}>{t('analyses.point')}</th>
              {deleteOn && (
                <th className={cn([styles.cell, styles.cellHeader])}>{}</th>
              )}
            </tr>
          </thead>
          <tbody className={styles.tableBody}>
            {AnalysisList?.isLoading && (
              <div className="loader" />
            )}
            {!AnalysisList.isLoading && datanull ? (
              <div>
                <p>{t('global.no_results_found')}</p>
              </div>
            ) : null}
            {dataPage?.map((analyse) => (
              <tr key={analyse.id}>
                <td className={
                  analyse.type === 'legionella'
                    ? isPneumophila(analyse)
                    : otherAnalyse(analyse)
                }
                >
                  {t(`analyses.${analyse.type}`)}
                </td>
                <td className={styles.cell}>{new Date(analyse?.samplingDate).toLocaleDateString()}</td>
                <td className={styles.celldoc}>
                  {Permissions({ permission: 'ANALYSIS_READ' }) !== undefined
                    || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
                    ? (
                      <Button
                        label={(
                          <FontAwesomeIcon icon={faFileArrowDown} transform="grow-6" />
                        )}
                        type="button"
                        className="action edit"
                        title={t('trainings.download')}
                        onClick={() => dowloaddoc(analyse.id, analyse.reportFileName)}
                      />
                    ) : null }
                </td>
                <td className={styles.cellaccordion}>
                  <div>
                    {analyse.type === 'legionella' && analyse.analysisPoints?.length > 0 ? (
                      <div className={cn([styles.accordions, 'accordion'])} id="accordionExample">
                        <div className={cn([styles.accordionItem, 'accordion-item'])}>
                          <h2 className="accordion-header" id={`heading${analyse.id}`}>
                            <button
                              className={cn([styles.accordion_button, 'accordion-button'])}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse${analyse.id}`}
                              aria-expanded="false"
                              aria-controls={`collapse${analyse.id}`}
                              onClick={() => handleAccordionClick(analyse.id)}
                            >
                              {`DÉTAIL (${analyse.analysisPoints?.length}) `}
                              <FontAwesomeIcon icon={faCircleInfo} size="lg" />
                            </button>
                          </h2>
                          <tbody className={styles.tableBodyPneumophila}>
                            {analyse.analysisPoints?.map((point) => (
                              <div
                                id={`collapse${analyse.id}`}
                                className={cn([styles.accordion, `accordion-collapse collapse ${activeIndex === analyse.id ? 'show' : ''}`])}
                                aria-labelledby={`heading${analyse.id}`}
                                data-bs-parent="#accordionExample"
                              >
                                <th className={styles.name}>{point.name}</th>
                                <th className={cn([couleur(point.isPneumophila, point.quantity)])}>
                                  <span>
                                    {parseInt(point.quantity, 10) < 10 || point.quantity === '' || point.quantity === null ? (
                                      <span>
                                        &lt; 10 UCF/L
                                      </span>
                                    ) : (
                                      <span>
                                        {`${point.quantity} UCF/L`}
                                      </span>
                                    )}
                                  </span>
                                </th>
                                <th
                                  className={cn([couleur(point.isPneumophila, point.quantity)])}
                                >
                                  {displayPneumophila(point.isPneumophila, point.quantity)}
                                </th>
                              </div>
                            ))}
                          </tbody>
                        </div>
                      </div>
                    ) : analyse.analysisPoints?.length !== 0 ? (
                      <div className={cn([styles.accordions, 'accordion'])} id="accordionExample">
                        <div className={cn([styles.accordionItem, 'accordion-item'])}>
                          <h2 className="accordion-header" id={`heading${analyse.id}`}>
                            <button
                              className={cn([styles.accordion_button, 'accordion-button'])}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse${analyse.id}`}
                              aria-expanded="false"
                              aria-controls={`collapse${analyse.id}`}
                              onClick={() => handleAccordionClick(analyse.id)}
                            >
                              {`DÉTAIL (${analyse.analysisPoints?.length}) `}
                              <FontAwesomeIcon icon={faCircleInfo} size="lg" />
                            </button>
                          </h2>
                          <tbody className={styles.tableBodyConforme}>
                            {analyse.analysisPoints?.map((point) => (
                              <tr
                                id={`collapse${analyse.id}`}
                                className={cn([styles.accordion, `accordion-collapse collapse ${activeIndex === analyse.id ? 'show' : ''}`])}
                                aria-labelledby={`heading${analyse.id}`}
                                data-bs-parent="#accordionExample"
                                point={point.id}
                              >
                                <th className={styles.name}>{point.name}</th>
                                <th className={styles.conforme}>
                                  <span>
                                    {point.valid !== null && point.valid === true ? (
                                      <div className={styles.green}>
                                        conforme
                                      </div>
                                    ) : (
                                      <div className={styles.noConforme}>
                                        non conforme
                                      </div>
                                    )}
                                  </span>
                                </th>
                              </tr>
                            ))}
                          </tbody>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <p>{t('global.no_results_found')}</p>
                      </div>
                    )}
                  </div>
                </td>
                {deleteOn && (
                  <td className={cn([styles.cell, styles.center])}>
                    {Permissions({ permission: 'ANALYSIS_DELETE' }) !== undefined
                      ? (
                        <button
                          type="button"
                          className="action suppr"
                          title={t('analyses.suppr')}
                          onClick={() => handleModal(analyse.id)}
                        >
                          <FontAwesomeIcon icon={faTrashCan} />
                        </button>
                      ) : null }
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={styles.pagination}>
        <Pagination
          className={styles.pagination}
          previousLabel={(
            <FontAwesomeIcon icon={faChevronLeft} />
          )}
          nextLabel={(
            <FontAwesomeIcon icon={faChevronRight} />
          )}
          onPageChange={onPageChange}
          totalCount={data?.length < 10 ? 0 : info?.data?.count || 0}
          currentPage={page}
          pageSize={10}
          numberElementsDisplayed={data?.length < 10 ? 0 : 10}
        />
      </div>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyles}
      >
        <p className="modal_paragraph">
          {t('global.modal_content')}
        </p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
            onClick={handleDelete}
          />
        </div>
      </Modal>
    </div>
  );
}

AnalyseList.propTypes = {
  AnalysisList: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteOn: PropTypes.bool,
};

AnalyseList.defaultProps = {
  deleteOn: false,
};

export default AnalyseList;
