/* eslint-disable react/prop-types */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faTrashCan, faChevronLeft, faChevronRight, faFileCsv, faEdit, faEye,
} from '@fortawesome/free-solid-svg-icons';
// Components
import { debounce } from 'lodash';
import useAppContext from '../../store/useAppContext';
import Layout from '../../components/template/Layout';
import Table from '../../components/molecules/Table/Table';
import Button from '../../components/atoms/Button/Button';
import Pagination from '../../components/molecules/Pagination';
import Search from '../../components/molecules/Search/Search';
import Permissions from '../../components/molecules/Permissions/Permissions';
// Hooks
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
// Services
import {
  getDairyList,
  deleteDairy,
  getDairyDetail,
} from '../../services/dairy';
// Utils
import styles from './Dairy.module.css';
import { downloadFileCsv } from '../../utils/downloadFile';
import { getBuildingsList } from '../../services/structures';
import cn from '../../utils/cn';

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
  },
};

const customSignatureModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
    borderTop: '2rem solid var(--color-primary-600)',
    paddingTop: '0.5rem',
    textAlign: 'center',
  },
};

function Dairy() {
  // Hooks
  const { t } = useTranslation();
  const [context] = useAppContext();
  const navigate = useNavigate();
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });
  // States
  const [openModal, setOpenModal] = useState(false);
  const [openSignature, setOpenSignature] = useState(false);
  const [search, setSearch] = useState('');
  const [supplierToDelete, setsupplierToDelete] = useState('');
  const [dowloadInfo, setDowloadInfo] = useState([]);
  const [signature, setSignature] = useState([]);
  const [urlSignature, setUrlSignature] = useState([]);
  const [buildingId, setBuildingId] = useState();
  // API Calls
  const getBuildingsQuery = useQuery('buildings', () => getBuildingsList({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: (data) => {
      if (data?.data?.buildings?.length > 0) {
        setBuildingId(data?.data?.buildings[0]?.id);
      }
    },
  });
  const getDairyListQuery = useQuery(['getDairyList', buildingId, page, search], () => getDairyList({
    page: 0,
    bookletId: context?.choiceBooklet,
    buildingId,
    search,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    enabled: buildingId !== undefined,
    onSuccess: (data) => {
      setDowloadInfo(data?.data.diaries);
    },
    onError: () => {
      toast.error(t('dairy.error'));
    },
  });

  const deleteDairyMutation = useMutation(deleteDairy, {
    onSuccess: () => {
      navigate(-1);
      toast.success(t('dairy.succes_delete'));
      getDairyListQuery.refetch();
    },
  });
  const handleModal = (DairyToDelete) => {
    setOpenModal(true);
    setsupplierToDelete(DairyToDelete?.id);
  };

  const getDairyDetailMutation = useMutation(getDairyDetail, {
    onSuccess: (data) => {
      setSignature({ data: data?.data });
      setOpenSignature(true);
    },
  });

  const handleBuilding = (id) => {
    setBuildingId(id);
  };

  const handleSignature = (id, signatureFileName) => {
    setUrlSignature(`${process.env.REACT_APP_API_URL}diaries/download-signature/${id}/${signatureFileName}`);
    getDairyDetailMutation.mutate({ id });
  };

  const handleDelete = (id) => {
    setOpenModal(false);
    deleteDairyMutation.mutate(id);
  };

  const hearder = [
    {
      label: `${t('dairy.date')}`,
      key: 'date',
      cell: (row) => new Date(row?.original?.date).toLocaleDateString(),
    },
    {
      label: `${t('dairy.bulding')}`,
      key: 'buildingConcerned',
    },
    {
      label: `${t('dairy.equipement')}`,
      key: 'equipmentConcerned',
    },
    {
      label: `${t('dairy.type_inter')}`,
      key: 'interventionType',
      cell: (row) => t(`dairy.${row?.original?.interventionType}`),
    },
    {
      label: `${t('dairy.company')}`,
      key: 'company',
      cell: (row) => row?.original?.company?.toUpperCase() || '',
    },
    {
      label: `${t('dairy.intervenant')}`,
      key: 'stakeholder',
    },
    {
      label: `${t('dairy.desc')}`,
      key: 'action',
    },
  ];
  const handleDowload = () => {
    downloadFileCsv(dowloadInfo, hearder, `${t('dairy.title')}`);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: `${t('dairy.date')}`,
        accessor: 'date',
        Cell: ({ row: { original: { date } } }) => (
          <span>{new Date(date).toLocaleDateString()}</span>
        ),
      },
      {
        Header: `${t('dairy.bulding')}`,
        accessor: 'buildingConcerned',
      },
      {
        Header: `${t('dairy.equipement')}`,
        accessor: 'equipmentConcerned',
      },
      {
        Header: `${t('dairy.type_inter')}`,
        accessor: 'interventionType',
        Cell: ({ row: { original: { interventionType } } }) => (
          <span>{t(`dairy.${interventionType}`)}</span>
        ),
      },
      {
        Header: `${t('dairy.company')}`,
        accessor: 'company',
        Cell: ({ row: { original: { company } } }) => (
          <span>{company?.toUpperCase() || ''}</span>
        ),
      },
      {
        Header: `${t('dairy.intervenant')}`,
        accessor: 'stakeholder',
        Cell: ({ row: { original: { stakeholder } } }) => (
          <span>{stakeholder.toUpperCase()}</span>
        ),
      },
      {
        Header: `${t('dairy.desc')}`,
        accessor: 'action',
      },
      {
        Header: ' ',
        Cell: ({ row: { original: { id, signatureFileName } } }) => (
          <div className={styles.actions}>
            <div className={styles.eye}>
              <div>
                <Button
                  type="button"
                  className="action edit"
                  title={t('dairy.signatures')}
                  label={<FontAwesomeIcon icon={faEye} />}
                  onClick={() => handleSignature(id, signatureFileName)}
                />
              </div>
              {Permissions({ permission: 'DIARY_READ' }) !== undefined
                || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
                ? (
                  <button
                    type="button"
                    className="action edit"
                    title={t('dairy.edit')}
                    onClick={() => navigate(`/jounal/edit/${id}`)}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                ) : null }
              {Permissions({ permission: 'DIARY_DELETE' }) !== undefined
                ? (
                  <button
                    type="button"
                    className="action suppr"
                    title={t('dairy.suppr')}
                    onClick={() => handleModal({ id })}
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                  </button>
                ) : null }
            </div>
          </div>
        ),
      },
    ],
    [],
  );

  const navigateDashboards = () => {
    const establishment = context?.choiceEstablishment;
    let estab = establishment?.name?.replace(/ /g, '-');
    estab = estab?.replace('/', '');
    estab = estab?.replace(/-+/g, '-');
    if (context.choiceBooklet === 1) {
      navigate(`/sanitaire/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 2) {
      navigate(`/securite/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 3) {
      navigate(`/vehicule/${establishment.id}-${estab}/dashboards`);
    }
  };

  const Intervention = [
    {
      label: 'Commission de sécurité',
      value: 'safety_committee',
    },
    {
      label: 'Contrôle organisme agréé',
      value: 'approved_body_control',
    },
    {
      label: 'Contrôle de maintenance',
      value: 'maintenance_control',
    },
    {
      label: 'Contrôle d\'un laboratoire',
      value: 'laboratory_control',
    },
    {
      label: 'Formation',
      value: 'training',
    },
    {
      label: 'Dépannage/ Travaux',
      value: 'troubleshooting_or_worksite',
    },
    {
      label: 'Autre',
      value: 'other',
    },
  ];

  const handleSearch = debounce((e) => {
    onPageChange(1);
    switch (e?.target?.value) {
      case 'Commission de sécurité':
        setSearch('safety_committee');
        break;
      case 'Contrôle organisme agréé':
        setSearch('approved_body_control');
        break;
      case 'Contrôle de maintenance':
        setSearch('maintenance_control');
        break;
      case 'Contrôle d\'un laboratoire':
        setSearch('laboratory_control');
        break;
      case 'Formation':
        setSearch('training');
        break;
      case 'Dépannage/ Travaux':
        setSearch('troubleshooting_or_worksite');
        break;
      case 'Autre':
        setSearch('other');
        break;
      default:
        setSearch(e?.target?.value.toLowerCase());
        break;
    }
  }, 500);

  return (
    <>
      <Layout
        title={t('dairy.title')}
        layout="table"
        table={getDairyListQuery?.data}
        queryError={
          getDairyListQuery?.error
          || getDairyDetailMutation?.error
          || deleteDairyMutation?.error
        }
      >
        <header className="header">
          <div className="row mb-20">
            <button type="button" className="link" onClick={() => navigateDashboards()}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('navigation.dashboards')}</span>
            </button>
          </div>
          <div className="row">
            <h1 className="title">
              {t('dairy.title')}
            </h1>
            {Permissions({ permission: 'DIARY_CREATE' }) !== undefined
              ? (
                <Link className="add" to="/journal/add">
                  <FontAwesomeIcon icon={faPlus} />
                  <span className="add_label">{t('dairy.add')}</span>
                </Link>
              ) : null }
          </div>
          <div className="row">
            <button
              className="add"
              type="button"
              onClick={handleDowload}
            >
              <FontAwesomeIcon icon={faFileCsv} />
              {' '}
              {t('reports.download')}
            </button>
            <Search
              className="mb-20"
              onChange={handleSearch}
              select
              Selectoptions={Intervention}
            />
          </div>
        </header>
        <div className={styles.filters}>
          <div className={styles.filtersToComplete}>
            {getBuildingsQuery?.data?.data?.buildings?.map((building) => (
              building.bookletId !== 3 && (
              <Button
                type="button"
                label={`${building.name}`}
                className={cn([styles.filter, buildingId === building.id
                  ? styles.active
                  : '', 'shadow-md'])}
                onClick={() => handleBuilding(building?.id)}
              />
              )
            ))}
          </div>
        </div>
        <div className={styles.table}>
          <Table
            columns={columns || []}
            isLoading={getDairyListQuery.isLoading}
            data={getDairyListQuery?.data?.data?.diaries || []}
            hasSort
            pagination={(
              <Pagination
                previousLabel={(
                  <FontAwesomeIcon icon={faChevronLeft} />
                )}
                nextLabel={(
                  <FontAwesomeIcon icon={faChevronRight} />
                )}
                onPageChange={onPageChange}
                totalCount={getDairyListQuery?.data?.data?.total || 0}
                currentPage={page}
                pageSize={10}
              />
            )}
          />
        </div>
        <footer className="footer">
          <button type="button" className="link" onClick={() => navigateDashboards()}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('navigation.dashboards')}</span>
          </button>
        </footer>
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyles}
      >
        <p className="modal_paragraph">{t('global.modal_content')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
            onClick={() => handleDelete(supplierToDelete)}
          />
        </div>
      </Modal>
      <Modal
        isOpen={openSignature}
        onRequestClose={() => setOpenSignature()}
        style={customSignatureModalStyles}
      >
        <div className="modal_header center">
          <h2 className="modal_title center">{t('dairy.journal')}</h2>
        </div>
        <div className="modal_paragraph">
          <span className={styles.modal}>
            {t('dairy.desc')}
            :
            {' '}
          </span>
          <span className="modal_paragraph">{signature?.data?.action}</span>
        </div>
        <div className="modal_paragraph">
          <span className={styles.modal}>
            {t('dairy.type_inter')}
            :
            {' '}
          </span>
          <span className="modal_paragraph">{t(`dairy.${signature?.data?.interventionType}`)}</span>
        </div>
        <div className="modal_paragraph">
          <span className={styles.modal}>
            {t('dairy.intervenant')}
            :
            {' '}
          </span>
          <span className="modal_paragraph">{signature?.data?.stakeholder}</span>
        </div>
        <div className="modal_paragraph">
          <span className={styles.modal}>
            {t('dairy.date')}
            :
            {' '}
          </span>
          <span className="modal_paragraph">{new Date(signature?.data?.date).toLocaleDateString()}</span>
        </div>
        <div className="modal_paragraph">
          <span className={styles.modal}>
            {t('dairy.bulding')}
            :
            {' '}
          </span>
          <span className="modal_paragraph">{signature?.data?.buildingConcerned}</span>
        </div>
        <div className="modal_paragraph">
          <span className={styles.modal}>
            {t('dairy.equipement')}
            :
            {' '}
          </span>
          <span className="modal_paragraph">{signature?.data?.equipmentConcerned}</span>
        </div>
        <img src={urlSignature} alt="signature" />
        {/* <p className="modal_paragraph">{signature?.data?.stakeholder}</p> */}
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.close')}
            onClick={() => setOpenSignature(false)}
          />
        </div>
      </Modal>
    </>
  );
}

export default Dairy;
