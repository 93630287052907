/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faFileArrowDown, faEdit, faTrashCan, faChevronLeft, faChevronRight, faFileCsv,
} from '@fortawesome/free-solid-svg-icons';
// Components
import Layout from '../../components/template/Layout';
import Table from '../../components/molecules/Table/Table';
import Button from '../../components/atoms/Button/Button';
import Pagination from '../../components/molecules/Pagination';
import Search from '../../components/molecules/Search/Search';
import Permissions from '../../components/molecules/Permissions/Permissions';
// Hooks
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
import useAppContext from '../../store/useAppContext';
// Services
import { getTrainingsList, deleteTraining } from '../../services/trainings';
// Utils
import styles from './Trainings.module.css';
import { TRAINING_TYPES, TRAINING_NAMES } from '../../utils/constant';
import { downloadFileCsv } from '../../utils/downloadFile';
import cn from '../../utils/cn';

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
  },
};

function Trainings() {
  // States
  const [openModal, setOpenModal] = useState(false);
  const [trainingToDelete, setTrainingToDelete] = useState({
    id: null,
  });
  const [search, setSearch] = useState('');
  // Hooks
  const { t } = useTranslation();
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });
  const [context] = useAppContext();
  const navigate = useNavigate();
  const [dowloadInfo, setDowloadInfo] = useState([]);

  const navigateDashboards = () => {
    const establishment = context?.choiceEstablishment;
    let estab = establishment?.name?.replace(/ /g, '-');
    estab = estab?.replace('/', '');
    estab = estab?.replace(/-+/g, '-');
    if (context.choiceBooklet === 1) {
      navigate(`/sanitaire/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 2) {
      navigate(`/securite/${establishment.id}-${estab}/dashboards`);
    }
    if (context.choiceBooklet === 3) {
      navigate(`/vehicule/${establishment.id}-${estab}/dashboards`);
    }
  };

  useEffect(() => {
    if (context.choiceBooklet === 1) navigateDashboards();
  }, [context.choiceBooklet]);

  // API Calls
  const getTrainingsListQuery = useQuery(['trainings', page, search], () => getTrainingsList({
    page: 0,
    search,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: (response) => {
      setDowloadInfo(response?.data?.trainings);
    },
  });
  const docFile = (id, fileName) => {
    window.open(`${process.env.REACT_APP_API_URL}trainings/download-document/${id}/${fileName}`, '_blank');
  };
  const deleteTrainingMutation = useMutation(deleteTraining, {
    onSuccess:
      () => {
        getTrainingsListQuery.refetch();
        toast.success(t('trainings.delete_success'));
      },
  });

  const handleSearch = debounce((e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  }, 500);

  const handleModal = (selectedTrainingToDelete) => {
    setOpenModal(true);
    setTrainingToDelete(selectedTrainingToDelete);
  };

  const handleDelete = () => {
    setOpenModal(false);
    deleteTrainingMutation.mutate(trainingToDelete?.id);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: `${t('trainings.date')}`,
        accessor: 'date',
        Cell: ({ row: { original: { date } } }) => (
          format(new Date(date), 'dd/MM/yyyy')
        ),
      },
      {
        Header: `${t('trainings.company')}`,
        accessor: 'society',
        Cell: ({
          row: {
            original: {
              speakerName, type,
            },
          },
        }) => (
          `${type === TRAINING_TYPES.FIRE_SAFETY_PREVENTION
            ? speakerName || t('add_training.house_keeping')
            : '-'}`
        ),
      },
      {
        Header: `${t('trainings.fullname')}`,
        accessor: 'fullname',
        Cell: ({
          row: {
            original: {
              firstName, lastName, type,
            },
          },
        }) => (
          `${type === TRAINING_TYPES.FIRE_SAFETY_PREVENTION
            ? '-'
            : `${firstName} ${lastName}`}`
        ),
      },
      {
        Header: `${t('trainings.type')}`,
        accessor: 'type',
        Cell: ({ row: { original: { type } } }) => (
          `${type === TRAINING_TYPES?.FIRE_SAFETY_PREVENTION
            ? t('add_training.fire_safety_prevention')
            : t('add_training.maintenance_manager')}`
        ),
      },
      {
        Header: `${t('trainings.name')}`,
        accessor: 'name',
        Cell: ({ row: { original: { name } } }) => (
          `${name === TRAINING_NAMES.HANDLING_EMERGENCY_MEANS ? t('add_training.handling_emergency_means') : ''}
          ${name === TRAINING_NAMES.EVACUATION ? t('add_training.evacuation') : ''}
          ${name === TRAINING_NAMES.DESIGNATED_EMPLOYEES_EVACUATION
            ? t('add_training.designated_employees_evacuation')
            : ''
          }
          ${name === TRAINING_NAMES.UNANNOUNCED_EXERCISE ? t('add_training.unannounced_exercise') : ''}
          ${name === TRAINING_NAMES.SSI ? t('add_training.ssi') : ''}
          ${name === TRAINING_NAMES.INTERNAL ? t('add_training.internal') : ''}
          ${name === TRAINING_NAMES.SSIAP ? t('add_training.ssiap') : ''}
          ${name === TRAINING_NAMES.SST ? t('add_training.sst') : ''}
          ${name === TRAINING_NAMES.ELECTRICAL_CLEARANCE ? t('add_training.electrical_clearance') : ''}`
        ),
      },
      {
        Header: `${t('trainings.headcount')}`,
        accessor: 'headcount',
      },
      {
        Header: `${t('trainings.person_in_charge')}`,
        accessor: 'personInCharge',
      },
      {
        Header: `${t('trainings.document')}`,
        accessor: 'documentFile',
        Cell: ({ row: { original: { documentFileName, id } } }) => (
          documentFileName !== null && (
            <Button
              type="button"
              className="action"
              label={<FontAwesomeIcon icon={faFileArrowDown} transform="grow-6" />}
              title={t('trainings.download')}
              onClick={() => docFile(id, documentFileName)}
            />
          )
        ),
      },
      {
        Header: ' ',
        Cell: ({ row: { original: { id } } }) => (
          <div className="actions">
            {Permissions({ permission: 'TRAINING_READ' }) !== undefined
              || Permissions({ permission: 'BOOKLET_READ' }) !== undefined
              ? (
                <Link
                  className="action"
                  to={`/formations/edit/${id}`}
                  title={t('trainings.edit')}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Link>
              ) : null }
            {Permissions({ permission: 'TRAINING_DELETE' }) !== undefined
              ? (
                <Button
                  type="button"
                  className="action suppr"
                  label={<FontAwesomeIcon icon={faTrashCan} />}
                  title={t('trainings.suppr')}
                  onClick={() => handleModal({ id })}
                />
              ) : null }
          </div>
        ),
      },
    ],
    [],
  );
  const hearder = [
    {
      label: 'Date',
      key: `${t('trainings.date')}`,
      cell: (row) => new Date(row.original.date).toLocaleDateString(),
    },
    {
      label: 'Société',
      key: `${t('trainings.company')}`,
      cell: (row) => {
        const {
          type, speakerName, firstName, lastName,
        } = row.original;
        return `${type === TRAINING_TYPES.FIRE_SAFETY_PREVENTION
          ? speakerName || t('add_training.house_keeping')
          : `${firstName} ${lastName}`}`;
      },
    },
    {
      label: 'Type',
      key: `${t('trainings.type')}`,
      cell: (row) => {
        const { type } = row.original;
        return `${type === TRAINING_TYPES.FIRE_SAFETY_PREVENTION
          ? t('add_training.fire_safety_prevention')
          : t('add_training.maintenance_manager')}`;
      },
    },
    {
      label: 'Nom',
      key: `${t('trainings.name')}`,
      cell: (row) => {
        const { name } = row.original;
        if (name === TRAINING_NAMES.HANDLING_EMERGENCY_MEANS) {
          return t('add_training.handling_emergency_means');
        }
        if (name === TRAINING_NAMES.EVACUATION) {
          return t('add_training.evacuation');
        }
        if (name === TRAINING_NAMES.DESIGNATED_EMPLOYEES_EVACUATION) {
          return t('add_training.designated_employees_evacuation');
        }
        if (name === TRAINING_NAMES.UNANNOUNCED_EXERCISE) {
          return t('add_training.unannounced_exercise');
        }
        if (name === TRAINING_NAMES.SSI) {
          return t('add_training.ssi');
        }
        if (name === TRAINING_NAMES.INTERNAL) {
          return t('add_training.internal');
        }
        if (name === TRAINING_NAMES.SSIAP) {
          return t('add_training.ssiap');
        }
        if (name === TRAINING_NAMES.SST) {
          return t('add_training.sst');
        }
        if (name === TRAINING_NAMES.ELECTRICAL_CLEARANCE) {
          return t('add_training.electrical_clearance');
        }
        return '';
      },
    },
    {
      key: `${t('trainings.headcount')}`,
      label: 'Effectif',
      cell: (row) => {
        const { headcount } = row.original;
        return `${headcount}`;
      },
    },
    {
      key: `${t('trainings.person_in_charge')}`,
      label: 'Responsable',
      cell: (row) => row.original.personInCharge,
    },
  ];
  const handleDowload = () => {
    downloadFileCsv(dowloadInfo, hearder, `${t('trainings.title')}`);
  };

  return (
    <>
      <Layout
        title={t('trainings.title')}
        queryError={getTrainingsListQuery?.error || deleteTrainingMutation?.error}
        layout="table"
      >
        <header className="header">
          <div className="row mb-20">
            <button type="button" className="link" onClick={() => navigateDashboards()}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('global.dashboard')}</span>
            </button>
          </div>
          {Permissions({ permission: 'TRAINING_CREATE' }) !== undefined
            ? (
              <div className="row">
                <h1 className="title">{t('trainings.title')}</h1>
                <Link className={cn([styles.add, 'add col-1'])} to="/formations/add">
                  <FontAwesomeIcon icon={faPlus} />
                  <span className="add_label">{t('trainings.add')}</span>
                </Link>
              </div>
            ) : null }
          <div className={cn([styles.add, 'row'])}>
            <button
              className="add"
              type="button"
              onClick={handleDowload}
            >
              <FontAwesomeIcon icon={faFileCsv} />
              {' '}
              {t('trainings.downloadList')}
            </button>
            <Search className="mb-20" onChange={handleSearch} />
          </div>
        </header>
        <div className={styles.table}>
          <Table
            columns={columns}
            isLoading={getTrainingsListQuery.isLoading}
            data={getTrainingsListQuery?.data?.data?.trainings || []}
            hasSort
            pagination={(
              <Pagination
                previousLabel={(
                  <FontAwesomeIcon icon={faChevronLeft} />
                )}
                nextLabel={(
                  <FontAwesomeIcon icon={faChevronRight} />
                )}
                onPageChange={onPageChange}
                totalCount={getTrainingsListQuery?.data?.data?.total || 0}
                currentPage={page}
                pageSize={10}
              />
            )}
          />
        </div>
        <footer>
          <div className="row mb-20">
            <button type="button" className="link" onClick={() => navigateDashboards()}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{t('global.dashboard')}</span>
            </button>
          </div>
        </footer>
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyles}
      >
        <p className="modal_paragraph">{t('global.modal_content')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
            onClick={handleDelete}
          />
        </div>
      </Modal>
    </>
  );
}

export default Trainings;
