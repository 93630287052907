/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
// Components
import CardDashboard from '../../components/molecules/CardBaseDocument/CardBaseDocument';
import Layout from '../../components/template/Layout';
// Hooks
// Utils
import { getDocumentwithid } from '../../services/docBase';
// styles
import styles from './DocumentBaseSousCategorie.module.css';
import useAppContext from '../../store/useAppContext';

function Documentary() {
  // Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [title, setTitle] = useState();
  const [getId, setId] = useState();
  const [documentBaseItemCategory, setDocumentBaseItemCategory] = useState();
  const [context] = useAppContext();

  const getDocumentwithidQuery = useQuery(['getDocumentwithid'], () => getDocumentwithid({
    id,
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: (data) => {
      setDocumentBaseItemCategory(data?.data?.documentBaseItemTypes);
      setTitle(data?.data?.name);
      setId(data?.data?.id);
    },
  });

  return (
    <Layout
      title={t('documentary.title')}
      layout="table"
      queryError={
        getDocumentwithidQuery?.error
      }
    >
      <header className="header">
        <div className="row mb-20">
          <button type="button" className="link" onClick={() => navigate('/base-documentaire')}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('documentary.back_to_dashboard')}</span>
          </button>
        </div>
        <div className="row">
          <h1 className="title">
            {title}
          </h1>
        </div>
      </header>
      <div className={getId === 5 ? styles.ssi : styles.Sanitaire}>
        {documentBaseItemCategory?.map((item) => (
          <CardDashboard
            title={item.name}
            picto="ssi"
            classNames={item.categoryId === 5 ? styles.cardSSI : styles.card}
            onClick={() => navigate(`/base-documentaire/${id}/${item?.id}`)}
            Numbers={item.countDocument}
            borderTopColored
            disabled={item.countDocument === 0}
          />
        ))}
      </div>
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigate('/base-documentaire')}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('documentary.back_to_dashboard')}</span>
        </button>
      </footer>
    </Layout>
  );
}

export default Documentary;
