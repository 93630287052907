import { useQuery, useMutation } from 'react-query';
import React, { useState } from 'react';
import {
  faEdit,
  faChevronLeft,
  faMinus,
  faCheck,
  faChevronRight,
  faTrashCan,
  faCircleCheck,
  faFloppyDisk,
  faPenClip,
  faShareAlt,
  faPlus,
  faXmark,
  faMessage,
  faEnvelopeOpenText,
} from '@fortawesome/free-solid-svg-icons';
import {
  useNavigate,
  Link,
  useParams,
  useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Modal from 'react-modal';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import useAppContext from '../../store/useAppContext';
import {
  getremaining,
  postObservation,
  getCommentaire,
  deleteObservation,
  postCommentaire,
} from '../../services/registre';
import Button from '../../components/atoms/Button/Button';
import Layout from '../../components/template/Layout';
import styles from './Observation.module.css';
import cn from '../../utils/cn';
import Search from '../../components/molecules/Search/Search';
import Input from '../../components/atoms/Input/Input';
import Textarea from '../../components/atoms/Textarea/Textarea';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';
import Pagination from '../../components/molecules/Pagination/Pagination';
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
import Permissions from '../../components/molecules/Permissions/Permissions';
/* eslint-disable react/prop-types */
/* eslint-disable no-else-return */
function ObservationRemaining() {
  const [count, setCount] = useState(-1);
  const [supplierToDelete, setsupplierToDelete] = useState('');
  const [array, setArray] = useState([]);
  const [Check, setCheck] = useState(false);
  const [Reming, setReming] = React.useState([]);
  const [sortTitle, setSortTitle] = React.useState([]);
  const [sortNumber, setSortNumber] = React.useState([]);
  const [title, setTitle] = React.useState('');
  const [showMenu, setShowMenu] = useState(false);
  const [MenuId, setMenuId] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [OpenCommentaire, setOpenCommentaire] = useState(false);
  const urlParams = useParams();
  const { t } = useTranslation();
  const [comments, setComments] = useState([]);
  const [commentId, setCommentId] = useState();
  const [commentNumber, setCommentNumber] = useState();
  const [searcher, setSearch] = useState('');
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });
  const navigate = useNavigate();
  const [context] = useAppContext();
  const location = useLocation();

  const customModalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };
  const customCommentaireStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      width: '50%',
      height: '50%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '2rem',
    },
  };

  const handleModal = (id) => {
    setOpenModal(true);
    if (id.length > 0) {
      setsupplierToDelete(id);
    } else {
      setsupplierToDelete([id]);
    }
  };

  // call api for get all observation

  const getReminngingQuery = useQuery(['getremaining', searcher], () => getremaining({
    id: urlParams.id,
    data: {
      bookletId: context?.choiceBooklet,
      establishmentId: context?.choiceEstablishment?.id,
      search: searcher,
    },
  }), {
    onSuccess: (data) => {
      setReming(data?.data?.observations);
      setTitle(data?.data?.interventionName);
    },
  });

  const handleCommentaire = (id, nb) => {
    setOpenCommentaire(true);
    setCommentId(id);
    setCommentNumber(nb);
    getReminngingQuery.refetch();
  };

  const getCommentaireQuery = useQuery(['getCommentaire'], () => getCommentaire({
    id: commentId,
  }), {
    enabled: commentId !== undefined && OpenCommentaire === true,
    onSuccess: (data) => {
      setComments(data?.data?.comments);
    },
  });
  const deleteObservations = useMutation(deleteObservation, {
    onSuccess: () => {
      toast.success(t('raisedObs.succesdelete'));
      getReminngingQuery.refetch();
    },
  });
  const handleDelete = () => {
    setOpenModal(false);
    deleteObservations.mutate(supplierToDelete);
  };

  const succes = (msg) => {
    getReminngingQuery.refetch();
    toast.success(msg);
  };
  // Call api for one observation
  const postObservations = useMutation(postObservation, {
    onSuccess: () => {
      succes(t('raisedObs.successave'));
    },
    onError: () => {
      toast.error(t('raisedObs.echecsave'));
    },
  });
  // formik creations
  const initialValues = {
    message: [],
    multiple: [],
    messagesAll: '',
    intitule: [],
    observationNumber: [],
    observationIds: [],
  };
  const validationSchema = Yup.object({
    message: Yup.mixed(),
    multiple: Yup.mixed(),
    messagesAll: Yup.string(),
    intitule: Yup.mixed(),
    observationNumber: Yup.mixed(),
    observationIds: Yup.mixed(),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
  });

  const postComments = useMutation(postCommentaire, {
    onSuccess: () => {
      succes(t('raisedObs.succescomment'));
      formik.values.message[commentId] = '';
      if (commentId !== undefined) {
        getCommentaireQuery.refetch();
      }
    },
    onError: () => {
      toast.error(t('raisedObs.echecsave'));
    },
  });

  const handleDeleteMobile = (id) => {
    setOpenModal(false);
    deleteObservations.mutate(id);
  };
  const handleAdd = () => {
    if (array.length < 1) {
      const counts = count + 1;
      setCount(counts);
      const arr = array;
      arr.push(counts);
      setArray(arr);
      setCheck(true);
    }
  };
  const filterId = (id) => {
    const arr = [];
    id.forEach((element) => {
      arr.push(element[0]);
    });
    return arr;
  };

  const submitComment = (item) => {
    const data = {
      observationIds: formik.values.multiple.length > 1 ? filterId(formik.values.observationIds) : [item],
      content: formik.values.multiple.length > 1 ? formik.values.messagesAll : formik.values.message[item],
    };
    postComments.mutate(data);
  };

  const submitObs = (item) => {
    const config = {
      reportId: parseInt(urlParams.id, 10),
      title: formik.values.intitule[item],
      number: formik.values.observationNumber[item],
    };
    postObservations.mutate(config);
  };
  const handleRemove = (id) => {
    const newArr = array.filter((item) => item !== id);
    setArray(newArr);
  };
  const handleCheckboxClick = (fieldName, fieldValue, isChecked) => {
    const updatedFieldValues = isChecked
      ? [...new Set([...formik.values[fieldName], [fieldValue.id, fieldValue.number]])]
      : formik.values[fieldName].filter(
        (observation) => observation[0] !== fieldValue.id,
      );
    const updatedObservationIds = isChecked
      ? [...new Set([...formik.values.observationIds, [fieldValue.id, fieldValue.number]])]
      : formik.values.observationIds.filter(
        (observation) => observation[0] !== fieldValue.id,
      );
    formik.setFieldValue(fieldName, updatedFieldValues);
    formik.setFieldValue('observationIds', updatedObservationIds);
  };
  const handleOpen = (id) => {
    setShowMenu(!showMenu);
    setMenuId(id);
  };
  const handleSearch = debounce((e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  }, 500);
  const handleCheckboxClickAll = (fieldName, isChecked) => {
    const remingValues = Reming?.map((vacation) => [vacation.id, vacation.number]) || [];
    const newValues = isChecked
      ? [...new Set(remingValues)]
      : [];
    const newObservationIds = isChecked
      ? [...new Set(remingValues)]
      : [];
    formik.setFieldValue(fieldName, newValues);
    formik.setFieldValue('observationIds', newObservationIds);
  };

  const showAndNavigate = (navigateTo) => {
    setShowMenu(false);
    navigate(navigateTo);
  };

  const closeComment = (status) => {
    setOpenCommentaire(status);
    getReminngingQuery.refetch();
  };

  const sortData = (data) => {
    let sortedData;
    if (data === 'number') {
      if (sortNumber === 'asc') {
        sortedData = Reming.sort((a, b) => b.number.localeCompare(a.number));
        setSortNumber('desc');
      } else if (sortNumber === 'desc') {
        sortedData = Reming.sort((a, b) => a.id - b.id);
        setSortNumber();
      } else {
        sortedData = Reming.sort((a, b) => a.number.localeCompare(b.number));
        setSortNumber('asc');
      }
      setSortTitle();
    } else if (data === 'title') {
      if (sortTitle === 'asc') {
        sortedData = Reming.sort((a, b) => b.title.localeCompare(a.title));
        setSortTitle('desc');
      } else if (sortTitle === 'desc') {
        sortedData = Reming.sort((a, b) => a.id - b.id);
        setSortTitle();
      } else {
        sortedData = Reming.sort((a, b) => a.title.localeCompare(b.title));
        setSortTitle('asc');
      }
      setSortNumber();
    }
    setReming(sortedData);
  };

  const sort = (data) => {
    if (!Reming) return '';
    if (data === 'asc') {
      return ' ↓';
    } else if (data === 'desc') {
      return ' ↑';
    }
    return ' ↕';
  };

  const navigateBack = () => {
    if (location.pathname.includes('/archives')) {
      navigate('/archives');
    } else if (context?.choiceBooklet === 1) {
      navigate('/monCarnet');
    } else if (context?.choiceBooklet === 2) {
      navigate('/registre');
    }
  };

  if (window.innerWidth > 768) {
    return (
      <>
        <Layout
          title="Observations restantes"
          layout="table"
          table={Reming}
          queryError={
            getReminngingQuery?.error
            || postObservations?.error
            || postComments?.error
          }
        >
          <header className="header">
            <div className="row mb-20">
              <button type="button" className="link" onClick={() => navigateBack()}>
                <FontAwesomeIcon icon={faChevronLeft} />
                <span>{t('observation.back')}</span>
              </button>
            </div>
            <div className={cn(['row', styles.row])}>
              <h1 className="title">
                {t('observation.restantTitle')}
                :
              </h1>
            </div>
            <span className="title-info">
              {title}
            </span>
          </header>
          <div className={styles.search}>
            <Search className="mb-20" onChange={handleSearch} />
          </div>
          <div className="row">
            <div className={cn([styles.wrapper, 'shadow-sm'])}>
              <table className={styles.table}>
                <thead className={styles.tableHeader}>
                  <tr className={styles.row}>
                    <th className={cn([styles.cell, styles.cellHeaderCheck])}>
                      <Checkbox
                        id="multiple[all]"
                        name="multiple[all]"
                        onChange={(event) => {
                          handleCheckboxClickAll('multiple', event.target.checked);
                        }}
                        onBlur={formik.handleBlur}
                        checked={formik.values.multiple?.length === Reming?.length}
                        value={formik.values.multiple}
                      />
                    </th>
                    <th
                      className={cn([styles.cell, styles.cellHeaderName, styles.cursor])}
                      onClick={() => Reming && sortData('title')}
                    >
                      {t('observation.observationName')}
                      {sort(sortTitle)}
                    </th>
                    <th
                      className={cn([styles.cell, styles.cellHeaderObs, styles.cursor])}
                      onClick={() => Reming && sortData('number')}
                    >
                      {t('observation.observationNumber')}
                      {sort(sortNumber)}
                    </th>
                    <th className={cn([styles.cell, styles.cellComment])}>
                      {t('observation.commentaire')}
                    </th>
                    <th className={cn([styles.cell, styles.cellbutton])}>
                      {t('actions')}
                    </th>
                  </tr>
                </thead>
                <tbody className={styles.tableBody}>
                  <tr className={cn([styles.row, styles.backblue])}>
                    {formik.values.multiple.length > 1 && (
                    <>
                      <td className={styles.cell}>
                        {' '}
                      </td>
                      <td className={styles.cell}>
                        {t('observation.message')}
                        :
                      </td>
                      <td className={styles.cellObs}>
                        {formik.values.multiple.map((id) => (
                          <span key={id}>
                            {' '}
                            {id[1]}
                            {' '}
                          </span>
                        ))}
                      </td>
                      <td className={cn([styles.cellCommentaire, styles.backblue])}>
                        <form onSubmit={formik.handleSubmit} className={cn(['form', styles.backblue])}>
                          <div className={styles.form}>
                            <Textarea
                              type="text"
                              name="messagesAll"
                              className={styles.form_input}
                              value={formik.values.messagesAll}
                              placeholder={t('observation.messageall')}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              required
                            />
                            <Button
                              type="button"
                              title={t('observation.commentall')}
                              className={cn([styles.form_submit, 'action', styles.backblue])}
                              label={<FontAwesomeIcon icon={faFloppyDisk} transform="grow-5" />}
                              onClick={() => submitComment()}
                              disabled={formik.values.messagesAll === ''}
                            />
                          </div>
                        </form>
                      </td>
                      <td className={styles.ButtonAction}>
                        <div className={styles.button}>
                          <Button
                            type="button"
                            className={cn([styles.backblue, 'action'])}
                            label={<FontAwesomeIcon icon={faPenClip} />}
                            title={t('observation.levéeall')}
                            onClick={() => navigate(
                              `/observations/finishall/${urlParams.id}`,
                              { state: { observationNumber: formik.values.observationIds } },
                            )}
                          />
                          <Button
                            type="button"
                            className={cn([styles.backblue, 'action'])}
                            label={<FontAwesomeIcon icon={faShareAlt} />}
                            title={t('observation.shareall')}
                            onClick={() => navigate(
                              `/observations/shareall/${urlParams.id}`,
                              { state: { observationNumber: formik.values.observationIds } },
                            )}
                          />
                        </div>
                      </td>
                    </>
                    )}
                  </tr>
                  {Reming?.map((info) => (
                    <tr key={info.id} className={styles.row}>
                      <td className={styles.cellCheck}>
                        <Checkbox
                          id={`multiple[${info.number}]`}
                          name={`multiple[${info.number}]`}
                          onChange={(event) => {
                            handleCheckboxClick('multiple', info, event.target.checked);
                          }}
                          onBlur={formik.handleBlur}
                          checked={formik.values.multiple?.some((observation) => observation[0] === info.id)}
                          value={formik.values.multiple}
                        />
                      </td>
                      <td className={styles.cellName}>
                        {info.title}
                      </td>
                      <td className={styles.cellObs}>
                        {info.number}
                      </td>
                      <td className={styles.cellCommentaire}>
                        <form onSubmit={formik.handleSubmit} className={styles.FORM} key={info.id}>
                          <div className={styles.form}>
                            <Button
                              type="button"
                              className={cn([styles.form_cancel, 'action'])}
                              alt="les commentaires"
                              title={t('observation.AllComments')}
                              label={(
                                <div className={styles.commentaire}>
                                  <FontAwesomeIcon icon={faMessage} transform="grow-5" />
                                  <span className={info?.commentCount !== 0 ? styles.badge : styles.noBadge}>
                                    {info?.commentCount}
                                  </span>
                                </div>
                            )}
                              onClick={() => handleCommentaire(info.id, info.number)}
                            />
                            <span className={styles.lastcomment}>{info.commentlastContent}</span>
                          </div>
                        </form>
                      </td>
                      <td className={cn([styles.cellAction, styles.center])}>
                        {formik.values.multiple.length <= 1
                          ? (
                            <div className={styles.button} key={info.id}>
                              {info.sharedAt !== null && info.sharedTo !== null
                                ? (
                                  <>
                                    <FontAwesomeIcon
                                      data-tip
                                      data-for={`userColResult${info.id}`}
                                      icon={faCircleCheck}
                                      className={styles.iconDevis}
                                      transform="grow-4"
                                      size="m"
                                    />
                                    <ReactTooltip
                                      id={`userColResult${info.id}`}
                                      place="top"
                                      type="info"
                                      effect="solid"
                                    >
                                      {t('observation.devis_by')}
                                      :
                                      {' '}
                                      {info.sharedTo.split(',').join(' /')}
                                      {' '}
                                      <br />
                                      {t('observation.le')}
                                      :
                                      {' '}
                                      {new Date(info.sharedAt).toLocaleDateString()}
                                    </ReactTooltip>
                                  </>
                                ) : null }
                              {Permissions({ permission: 'OBSERVATION_LIFT' }) !== undefined
                                ? (
                                  <Link
                                    className="action"
                                    to={`/Observations/finished/${info.id}`}
                                    title={t('observation.up')}
                                  >
                                    <FontAwesomeIcon icon={faPenClip} transform="5" />
                                  </Link>
                                ) : null }
                              {Permissions({ permission: 'OBSERVATION_SHARE' }) !== undefined
                                ? (
                                  <Button
                                    type="button"
                                    className="action"
                                    label={<FontAwesomeIcon icon={faShareAlt} />}
                                    title={t('observation.share')}
                                    onClick={() => navigate(
                                      `/observations/share/${info.id}`,
                                      { state: { observationNumber: info.id } },
                                    )}
                                  />
                                ) : null }
                              {Permissions({ permission: 'OBSERVATION_EDIT' }) !== undefined
                                ? (
                                  <Link
                                    className="action"
                                    to={`/observations/edit/${info.id}`}
                                    title={t('observation.edit')}
                                  >
                                    <FontAwesomeIcon icon={faEdit} transform="5" />
                                  </Link>
                                ) : null }
                              {Permissions({ permission: 'OBSERVATION_DELETE' }) !== undefined
                                ? (
                                  <Button
                                    type="button"
                                    className="action suppr"
                                    label={<FontAwesomeIcon icon={faTrashCan} transform="5" />}
                                    title={t('observation.delete')}
                                    onClick={() => handleModal(info.id)}
                                  />
                                ) : null }
                            </div>
                          ) : null }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className={styles.pagination}>
                <Pagination
                  previousLabel={(
                    <FontAwesomeIcon icon={faChevronLeft} />
                  )}
                  nextLabel={(
                    <FontAwesomeIcon icon={faChevronRight} />
                  )}
                  onPageChange={onPageChange}
                  totalCount={Reming?.length || 0}
                  currentPage={page}
                  pageSize={10}
                  numberElementsDisplayed={Reming?.length || 0}
                />
              </div>
              {Permissions({ permission: 'OBSERVATION_CREATE' }) !== undefined
                ? (
                  <Button
                    type="button"
                    className={cn([styles.add, 'form_button'])}
                    label={t('addReports.add_observations')}
                    icon={<FontAwesomeIcon icon={faPlus} />}
                    onClick={() => handleAdd()}
                  />
                ) : null }
              { Check === true && array.length > 0 && (
              <section className="observations form_group form_group--fullwidth form_group--highlighted">
                <h3 className="title">{t('addReports.observations')}</h3>
                <form onSubmit={formik.handleSubmit} className={cn([styles.formulairess])}>
                  {array.map((item) => (
                    <div className="form_row">
                      <div className={cn([styles.form_group, 'form_group'])}>
                        <Input
                          name={`intitule[${item}]`}
                          label={t('observation.intitule')}
                          type="text"
                          value={formik.values.intitule[item]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      <div className={cn([styles.form_groups, 'form_group'])}>
                        <Input
                          name={`observationNumber[${item}]`}
                          label={t('observation.observationNumber')}
                          type="text"
                          value={formik.values.observationNumber[item]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      <section className={cn([styles.form_buttons, 'form_button'])}>
                        <div className={cn([styles.form_delete, 'form_button'])}>
                          <Button
                            type="button"
                            label={<FontAwesomeIcon icon={faMinus} />}
                            onClick={() => handleRemove(item)}
                          />
                        </div>
                        <div className={cn([styles.form_succes, 'form_button'])}>
                          <Button
                            type="button"
                            label={<FontAwesomeIcon icon={faCheck} />}
                            onClick={() => {
                              if (formik.values.intitule[item] && formik.values.observationNumber[item]) {
                                submitObs(item);
                                handleRemove(item);
                              }
                            }}
                          />
                        </div>
                      </section>
                    </div>
                  ))}
                </form>
              </section>
              )}
            </div>
          </div>
          <br />
          <footer>
            <div className="row mt-20">
              <button type="button" className="link" onClick={() => navigateBack()}>
                <FontAwesomeIcon icon={faChevronLeft} />
                <span>{t('observation.back')}</span>
              </button>
            </div>
          </footer>
        </Layout>
        <Modal
          isOpen={openModal}
          onRequestClose={() => setOpenModal(false)}
          style={customModalStyle}
        >
          <p className="modal_paragraph">{t('global.modal_content')}</p>
          <div className="modal_actions">
            <Button
              type="button"
              className="modal_button"
              label={t('global.modal_dismiss')}
              onClick={() => setOpenModal(false)}
            />
            <Button
              type="button"
              className="modal_button modal_button--success"
              label={t('global.modal_validate')}
              onClick={() => handleDelete(supplierToDelete)}
            />
          </div>
        </Modal>
        <Modal
          isOpen={OpenCommentaire}
          onRequestClose={() => closeComment(false)}
          style={customCommentaireStyle}
        >
          <div>
            <Button
              type="button"
              className={cn([styles.buttoncommentaire, 'modal_buttons'])}
              label={<FontAwesomeIcon icon={faXmark} transform="10" />}
              onClick={() => closeComment(false) && getReminngingQuery.refetch()}
            />
          </div>
          <p className={cn([styles.Titlecommentaire, 'modal_paragraph'])}>
            {t('raisedObs.TitleCommentaire')}
            {' '}
            {commentNumber}
          </p>
          <br />
          <div className={styles.sendComment}>
            <Textarea
              type="text"
              name={`message[${commentId}]`}
              className={styles.form_textarea}
              value={formik.values.message[`${commentId}`]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={formik.values.multiple.length > 1}
              required
            />
            {Permissions({ permission: 'COMMENT_CREATE' }) !== undefined
              ? (
                <Button
                  type="button"
                  className={cn([styles.submit_comment])}
                  title={t('observation.saveYourComment')}
                  label={<FontAwesomeIcon icon={faFloppyDisk} transform="grow-5" />}
                  onClick={() => submitComment(commentId)}
                  disabled={formik.values.message[`${commentId}`] === undefined}
                />
              ) : null }
          </div>
          <div className={styles.getcommentaire}>
            {comments?.map((commentaire) => (
              <div key={commentaire.id} className={styles.commentaires}>
                <div className={styles.divcommentaire}>
                  <p className={styles.comment}>
                    {commentaire.content}
                  </p>
                  <div className={styles.usercommentaire}>
                    <div>
                      <p>{commentaire.creatorName}</p>
                      <p>{new Date(commentaire.createdAt).toLocaleDateString()}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Modal>
      </>
    );
  } else {
    return (
      <>
        <Layout
          title="Observations restantes"
          layout="table"
          table={Reming}
          queryError={
            getReminngingQuery?.error
            || postObservations?.error
            || deleteObservations?.error
          }
        >
          <header className="header">
            <div className="row mb-20">
              <button type="button" className="link" onClick={() => navigate('/registre')}>
                <FontAwesomeIcon icon={faChevronLeft} />
                <span>{t('global.dashboard')}</span>
              </button>
            </div>
            <div className={cn(['row', styles.row])}>
              <h1 className="title">
                {t('observation.restantTitle')}
                :
              </h1>
            </div>
            <span className="title-info">
              {title}
            </span>
          </header>
          <div className="row">
            <Search className="mb-20" onChange={handleSearch} />
          </div>
          <div className="row">
            <div className={cn([styles.wrapper, 'shadow-sm'])}>
              <table className={styles.table}>
                <thead className={styles.tableHeader}>
                  <tr className={styles.row}>
                    <th className={cn([styles.cellMobile, styles.cellHeaderNameMobile])}>
                      {' '}
                    </th>
                  </tr>
                </thead>
                <tbody className={styles.tableBody}>
                  {Reming?.map((observation) => (
                    <tr className={styles.cells}>
                      <td className={styles.row}>
                        {observation.devis === true && (
                          <>
                            <FontAwesomeIcon
                              data-tip
                              data-for={`userColResult${observation.id}`}
                              icon={faEnvelopeOpenText}
                              className={styles.icon}
                              transform="grow-8"
                              size="lg"
                            />
                            <ReactTooltip
                              id={`userColResult${observation.id}`}
                              place="top"
                              type="info"
                              effect="solid"
                            >
                              {t('observation.devis_send')}
                              :
                              {' '}
                              {observation.devisEmail}
                              {' '}
                              <br />
                              {t('observation.le')}
                              :
                              {' '}
                              {new Date(observation.devisDate).toLocaleDateString()}
                            </ReactTooltip>
                          </>
                        )}
                        {' '}
                        {' '}
                        Intitulé:
                        {' '}
                        {observation?.title}
                        <br />
                        Numero:
                        {' '}
                        {observation?.number}
                      </td>
                      <br />
                      <span className={styles.dropdown}>
                        <Button
                          onClick={() => handleOpen(observation.id, true)}
                          className={cn([styles.action_button, 'form_submit'])}
                          label={t('observation.action')}
                        />
                        {
                          showMenu && MenuId === observation.id ? (
                            <ul className={styles.dropdown_content}>
                              <li className={styles.dropdown__button}>
                                <Button
                                  label={t('observation.photo')}
                                  onClick={() => showAndNavigate(`/picture/${observation.id}`)}
                                  className={styles.photobtn}
                                />
                              </li>
                              <li className={styles.dropdown__button}>
                                <Button
                                  label={t('observation.attestationInterne')}
                                  onClick={() => showAndNavigate(`/internal_lifting/${observation.id}`)}
                                  className={styles.Regis_internebtn}
                                />
                              </li>
                              <li className={styles.dropdown__button}>
                                <Button
                                  label={t('observation.attestationOrganisme')}
                                  onClick={() => showAndNavigate(`/external_lifting/${observation.id}`)}
                                  className={styles.Organismebtn}
                                />
                              </li>
                            </ul>
                          ) : null
                        }
                        <Button
                          className={cn([styles.deletebtn, 'modal_button'])}
                          label={t('observation.deleteComment')}
                          variant="danger"
                          onClick={() => handleModal(observation.id)}
                        />
                      </span>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className={styles.pagination}>
                <Pagination
                  previousLabel={(
                    <FontAwesomeIcon icon={faChevronLeft} />
                  )}
                  nextLabel={(
                    <FontAwesomeIcon icon={faChevronRight} />
                  )}
                  onPageChange={onPageChange}
                  totalCount={Reming?.length || 0}
                  currentPage={page}
                  pageSize={10}
                  numberElementsDisplayed={Reming?.length || 0}
                />
              </div>
              <br />
              <button
                type="button"
                className={cn([styles.add, 'add col-3'])}
                onClick={() => handleAdd()}
              >
                <FontAwesomeIcon icon={faPlus} />
                <span>{t('observation.add')}</span>
              </button>
              { Check === true && array.length > 0 && (
              <form onSubmit={formik.handleSubmit} className={styles.formulaire}>
                <div>
                  {' '}
                </div>
                {array.map((item) => (
                  <>
                    <div className={styles.form_group}>
                      <Input
                        name={`intitule[${item}]`}
                        label={t('observation.intitule')}
                        type="text"
                        value={formik.values.intitule[item]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    <div className={styles.form_groups}>
                      <Input
                        name={`observationNumber[${item}]`}
                        label={t('observation.observationNumber')}
                        type="text"
                        value={formik.values.observationNumber[item]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    <div>
                      <Button
                        type="button"
                        className={styles.form_groupsubmits}
                        label={<FontAwesomeIcon icon={faCheck} />}
                        onClick={() => submitObs(item)}
                      />
                    </div>
                    <div>
                      <Button
                        type="button"
                        className={styles.form_groupsubmit}
                        label={<FontAwesomeIcon icon={faMinus} />}
                        onClick={() => handleRemove(item)}
                      />
                    </div>
                  </>
                ))}
              </form>
              )}
            </div>
          </div>
          <br />
          <footer>
            <div className="row mt-20">
              <button type="button" className="link" onClick={() => navigate('/registre')}>
                <FontAwesomeIcon icon={faChevronLeft} />
                <span>{t('global.dashboard')}</span>
              </button>
            </div>
          </footer>
        </Layout>
        <Modal
          isOpen={openModal}
          onRequestClose={() => setOpenModal(false)}
          style={customModalStyle}
        >
          <p className="modal_paragraph">{t('global.modal_content')}</p>
          <div className="modal_actions">
            <Button
              type="button"
              className="modal_button"
              label={t('global.modal_dismiss')}
              onClick={() => setOpenModal(false)}
            />
            <Button
              type="button"
              className="modal_button modal_button--success"
              label={t('global.modal_validate')}
              onClick={() => handleDeleteMobile(true)}
            />
          </div>
        </Modal>
      </>
    );
  }
}

export default ObservationRemaining;
